<template>
  <b-sidebar
    id="add-new-employee-sidebar"
    :visible="isAddNewEmployeeSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-employee-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ $t("message.employees.createNewEmployee") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitNewEmployee())"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="nome"
            rules="required"
          >
            <b-form-group label-for="Name">
              <template v-slot:label>
                {{ $t("message.tableHeader.name") }}
              </template>
              <b-form-input
                id="Name"
                v-model="employeeData.fullName"
                :state="getValidationState(validationContext)"
                placeholder="Digite o nome"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="email"
            rules="required|email"
          >
            <b-form-group label="Email" label-for="email">
              <template v-slot:label>
                {{ $t("Email") }}
              </template>
              <b-form-input
                id="email"
                v-model="employeeData.internalEmail"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Employee Role -->
          <validation-provider
            #default="validationContext"
            name="função"
            rules="required"
          >
            <b-form-group
              label="Função"
              label-for="employee-role"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.employees.function") }}
              </template>
              <v-select
                v-model="employeeData.projectRole"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="employee-role"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Contract -->
          <validation-provider
            #default="validationContext"
            name="contrato"
            rules="required"
          >
            <b-form-group
              label="Contrato"
              label-for="Contract"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("contract") }}
              </template>
              <v-select
                v-model="employeeData.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="contractOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="contract"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Timesheet -->
          <validation-provider
            #default="validationContext"
            name="status"
            rules="required"
          >
            <b-form-group
              label="Ativo"
              label-for="timeSheet"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.employees.active") }}
              </template>
              <v-select
                v-model="employeeData.timeSheet"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="timeSheet"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="phone"
            rules="required"
          >
            <b-form-group
              label-for="MobileNumber"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.employees.cellPhone") }}
              </template>
              <b-form-input
                v-mask="'(+##)## #####-#####'"
                id="employee-mobile-number"
                v-model="employeeData.mobilePhoneNumber"
                placeholder="+55(00)00000-0000"
              /><small style="color: red">{{ $t("dddAndCode") }}</small>

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!disabledButton"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-else
              disabled
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("message.buttons.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import getHeader from "@core/utils/auth";
import axios from "@axios";
import URL_API from "@core/utils/env";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewEmployeeSidebarActive",
    event: "update:is-add-new-employee-sidebar-active",
  },
  props: {
    isAddNewEmployeeSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    contractOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,

      //Param
      paramData: store.state.user.paramData,

      userData: store.state.user.userData,

      disabledButton: false,
    };
  },
  methods: {
    submitNewEmployee() {
      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          if (this.employeeData.internalEmail != undefined) {
            axios
              .get(
                `${URL_API}user-email/` + this.employeeData.internalEmail,
                {}
              )
              .then((response) => {
                if (response.data === true) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$i18n.t("Error"),
                      icon: "XIcon",
                      variant: "danger",
                      text: `Email inválido, por favor selecione outro e tente novamente`,
                    },
                  });
                  return false;
                } else {
                  axios
                    .get(
                      `${URL_API}employee-email/` +
                        this.employeeData.internalEmail,
                      {}
                    )
                    .then((response) => {
                      if (response.data === true) {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: this.$i18n.t("Error"),
                            icon: "XIcon",
                            variant: "danger",
                            text: `Email inválido, por favor selecione outro e tente novamente`,
                          },
                        });
                      } else {
                        this.disabledButton = true;
                        this.onSubmit();

                        this.employeeData.mobilePhoneNumber =
                          this.employeeData.mobilePhoneNumber.replace(
                            /\D/g,
                            ""
                          );

                        axios({
                          method: "post",
                          url:
                            `${URL_API}employee/` +
                            this.employeeData.projectRole,
                          headers: getHeader(this.userData),
                          data: this.employeeData,
                        })
                          .then(() => {
                            this.$toast({
                              component: ToastificationContent,
                              position: "top-right",
                              props: {
                                title: this.$i18n.t("message.toast.create"),
                                icon: "CheckIcon",
                                variant: "success",
                                text: `O funcionário ${this.employeeData.fullName} foi criado com sucesso`,
                              },
                            });
                          })
                          .then(() => {
                            this.$root.$emit("newListEmployee", null);
                            this.disabledButton = false;
                          })
                          .catch((error) => {
                            this.disabledButton = false;
                            console.log(error);
                          });
                      }
                    });
                }
              });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$i18n.t("Error"),
                icon: "XIcon",
                variant: "danger",
                text: `Email inválido, por favor selecione outro e tente novamente`,
              },
            });
          }
        }
      });
    },
  },

  setup(props, { emit }) {
    const blankEmployeeData = {
      fullName: "",
      internalEmail: "",
      projectRole: "",
      type: "",
      timeSheet: "",
    };

    const employeeData = ref(JSON.parse(JSON.stringify(blankEmployeeData)));
    const resetemployeeData = () => {
      employeeData.value = JSON.parse(JSON.stringify(blankEmployeeData));
    };

    const onSubmit = () => {
      emit("refetch-data");
      emit("update:is-add-new-employee-sidebar-active", false);
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetemployeeData);

    return {
      employeeData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-employee-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
