<template>
  <div>
    <employee-card-add-new
      :is-add-new-employee-sidebar-active.sync="isAddNewEmployeeSidebarActive"
      :role-options="roleOptions"
      :contract-options="contractOptions"
      :status-options="statusOptions"
    />
    <b-row>
      <!-- Search -->
      <b-col cols="12" md="12">
        <div
          v-bind:class="{
            'align-items-center justify-content-end': isActiveCards,
            'd-flex align-items-center justify-content-end': !isActiveCards,
          }"
        >
          <b-input-group class="mr-1">
            <b-form-input
              v-model="searchQuery"
              @keyup.enter="searchWord()"
              :placeholder="$t('message.bookmark.search')"
            />
            <b-input-group-append>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon"
                @click="searchWord()"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <br v-if="isActive" />
          <b-button
            variant="primary"
            @click="isAddNewEmployeeSidebarActive = true"
            v-if="restrictions('button_new_employee')"
          >
            <span class="text-nowrap">
              {{ $t("message.buttons.newEmployee") }}
            </span>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <section v-if="isBusy">
      <div
        class="text-center"
        style="color: #c0c0c0; margin-top: 17.2rem; margin-bottom: 17.2rem"
      >
        <b-spinner class="align-middle"></b-spinner>
        <strong> Carregando...</strong>
      </div>
    </section>

    <!-- Employees -->
    <section v-else class="grid-view">
      <b-card
        v-for="employee in employeeList"
        :key="employee.id"
        class="ecommerce-card"
        no-body
      >
        <b-row>
          <b-col class="text-center my-1" cols="4">
            <b-link
              :to="{ name: 'apps-employees-view', params: { id: employee.id } }"
            >
              <b-avatar size="100" v-bind:src="'data:image/jpeg;base64,'+employee.avatar" :text="avatarText(employee.fullName)" />
            </b-link>
          </b-col>
          <b-col cols="8">
            <b-card-body>
              <b-dropdown
                id="dropdown-1"
                class="text-body h4 float-right mr-1"
                size="sm"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="18"
                    class="cursor-pointer"
                  />
                </template>
                <b-dropdown-item
                  v-if="restrictions('button_profile_employee')"
                  @click="profileByUser(employee.id)"
                >
                  {{ $t("message.employees.profile") }}
                  <feather-icon icon="UserIcon" />
                </b-dropdown-item>
              </b-dropdown>
              <h5 class="item-name">
                <b-link
                  class="text-body"
                  :to="{
                    name: 'apps-employees-view',
                    params: { id: employee.id },
                  }"
                >
                  {{ employee.fullName }}
                </b-link>
              </h5>
              <b-link class="text-body">
                {{ employeeProfile(employee.projectRole) }}
              </b-link>

              <div class="mt-2">
                <div class="item-type">
                  <p>
                    <!-- {{ employee.type || "Não informado" }} <br />  -->
                    {{ employee.internalEmail || "Não informado" }}
                  </p>
                </div>
              </div>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </section>

    <!-- Pagination -->
    <section v-if="showPagination">
      <b-row>
        <b-col
          cols="12"
          class="d-flex align-items-center justify-content-center mt-2"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRegisters"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="my-table"
            @input="eventPage(currentPage)"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BDropdown,
  BSpinner,
  BDropdownItem,
  BBadge,
  BProgress,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
  BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import moment from "moment";
import router from "@/router";
import EmployeeCardAddNew from "./EmployeeCardAddNew.vue";
import { ref } from "@vue/composition-api";
import useEmployeesList from "../employees-list/useEmployeesList";
import { avatarText } from "@core/utils/filter";
import AccessControl from "@core/utils/access-control";
import ListWidth from "@core/utils/listWidth";
import store from "@/store";

export default {
  directives: {
    Ripple,
  },
  components: {
    EmployeeCardAddNew,
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BBadge,
    BProgress,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    BSpinner,
    BAvatar,
    router,
  },

  data() {
    return {
      employeeData: {},
      employeeList: [],
      searchQuery: "",
      totalRegisters: 0,
      perPage: 0,
      currentPage: 1,
      size: 15,
      isBusy: false,
      showPagination: true,
      allDataSize: 9999,
      employeeListAll: [],

      // Select
      roleOptions: [],
      contractOptions: [],

      //Param
      paramData: store.state.user.paramData,
      userData: store.state.user.userData,
    };
  },

  async created() {
    this.getEmployeeList();

    this.onResize();

    var roleList = [];
    await axios
      .get(`${URL_API}project_role`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].role;
          const role = "Indefinido";
          roleList.push(element);

          if (roleList[i] == "PROGRAMMER") {
            role = "Programador";
          }
          if (roleList[i] == "TESTER") {
            role = "Testador";
          }
          if (roleList[i] == "MANAGER") {
            role = "Gerente";
          }
          if (roleList[i] == "ANALYST") {
            role = "Analista";
          }
          if (roleList[i] == "ARCHITECT") {
            role = "Arquiteto";
          }
          if (roleList[i] == "TRAINEE") {
            role = "Estágiario";
          }
          if (roleList[i] == "UNDEFINED") {
            role = "Indefinido";
          }
          this.roleOptions.push({ label: role, value: roleList[i] });
        }
      });

    var typeList = [];
    await axios
      .get(`${URL_API}employee_type`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].type;
          typeList.push(element);
          this.contractOptions.push({ label: typeList[i], value: typeList[i] });
        }
      });
  },

  setup() {
    const isAddNewEmployeeSidebarActive = ref(false);

    const statusOptions = [
      { label: "Sim", value: true },
      { label: "Não", value: false },
    ];

    const { employeeProfile } = useEmployeesList();

    return {
      isAddNewEmployeeSidebarActive,
      statusOptions,
      employeeProfile,

      // Avatar
      avatarText,

      //Resize
      windowWidth: window.innerWidth,
      isActive: true,
      isActiveCards: true,
      limitCharactersValue: 20,
    };
  },

  mounted() {
    this.$root.$on("newListEmployee", (newListEmployee) => {
      this.isBusy = true;

      axios
        .get(
          `${URL_API}employee/list-dto?page=0&sort=id,${this.paramData.listSort}&size=${this.paramData.cardSize}`,
          {
            headers: getHeader(this.userData),
          }
        )
        .then((response) => {
          this.employeeList = response.data.content;
          this.totalRegisters = response.data.totalElements;
          this.perPage = response.data.numberOfElements;
          this.currentPage = 1;
          this.isBusy = false;
        });
    });

    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    async profileByUser(userId) {
      await axios
        .get(`${URL_API}user/employeeId/${userId}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.$router.push({
            name: "apps-user-profile",
            params: { id: response.data },
          });
        });
    },

    async getAllEmployeeList() {
      this.isBusy = true;
      this.showPagination = false;
      await axios
        .get(
          `${URL_API}employee/list-dto?page=0&sort=id,${this.paramData.listSort}&size=${this.allDataSize}`,
          {
            headers: getHeader(this.userData),
          }
        )
        .then((response) => {
          this.employeeListAll = response.data.content;
        });
    },

    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth > 1680) {
        this.isActive = false;
        this.limitCharactersValue = 20;
      } else {
        this.isActive = true;
        this.limitCharactersValue = 10;
      }

      this.isActiveCards = ListWidth(this.windowWidth);
    },

    restrictions(value) {
      return AccessControl(value);
    },

    async getEmployeeList() {
      this.isBusy = true;
      this.showPagination = false;
      await axios
        .get(
          `${URL_API}employee/list-dto?page=0&sort=id,${this.paramData.listSort}&size=${this.paramData.cardSize}`,
          { headers: getHeader(this.userData) }
        )
        .then((response) => {
          this.totalRegisters = response.data.totalElements;
          this.employeeList = response.data.content;
          this.perPage = response.data.numberOfElements;
          this.isBusy = false;
        });

      if (this.employeeList === null) {
        this.showPagination = false;
      } else {
        this.showPagination = true;
        this.currentPage = 1;
      }
    },

    async eventPage(currentPage) {
      this.isBusy = true;
      await axios
        .get(
          `${URL_API}employee/list-dto?page=${currentPage - 1}&sort=id,${
            this.paramData.listSort
          }&size=${this.paramData.cardSize}`,
          { headers: getHeader(this.userData) }
        )
        .then((response) => {
          this.employeeList = response.data.content;
          this.isBusy = false;
        });

      if (this.employeeList === null) {
        this.showPagination = false;
      } else {
        this.showPagination = true;
      }
    },

    async getEmployeeFilter() {
      this.isBusy = true;
      this.showPagination = false;
      await axios
        .get(`${URL_API}employee/search?searchTerm=${this.searchQuery}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.employeeList = [];
          if (response.data != []) {
            for (var i = 0; i < response.data.length; i++) {
              this.employeeList.push(response.data[i]);
            }
          }
          this.isBusy = false;
          this.showPagination = false;
        });
    },

    searchWord() {
      if (this.searchQuery) {
        this.getEmployeeFilter();
      } else {
        this.getEmployeeList();
      }
    },

    formattedDate(value) {
      if (value) {
        return this.$i18n.d(
          new Date(value + " EDT"),
          "short",
          this.$i18n.locale
        );
      }
      return this.$i18n.t("message." + "noDate");
    },

    async allEmployees() {},

    async pendingEmployees() {
      if (this.employeeListAll != null) {
        await this.getAllEmployeeList();
      }

      for (var i = 0; i < this.employeeListAll.length; i++) {}
    },

    async doneEmployees() {
      if (this.employeeListAll != null) {
        await this.getAllEmployeeList();
      }

      for (var i = 0; i < this.employeeListAll.length; i++) {}
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.item-function,
.item-type p {
  font-size: 12px;
  margin-bottom: 0rem;
}
</style>
